<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :key="resetTab" title="Cuadratura" :breadcrumbs="breadcrumbs"
      :hiddenTab="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_reconciliation'])">
        <v-btn class="mr-2" @click="isLoadMore=true, isUpdate = true, getList()" outlined :disabled="!reconciliationList?.length ">
          <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
        </v-btn>
        <v-btn v-if="$helpers.hasSomePermission(['add_reconciliation'])" @click="dialogCreate = true" color="blue-500"
          :ripple="false"><v-icon left>mdi-plus-box</v-icon>Nueva cuadratura</v-btn>
      </template>
      <template v-slot:custom-tab v-if="$helpers.hasSomePermission(['view_reconciliation'])">
        <v-tabs v-model="filter"
          class="mt-n3 pt-8 pl-4 pr-5 grey-background" :height="35" style=" position: absolute; z-index: 0 !important">
          <v-tab v-for="status in stepperList" :key="status.id" :ripple="false">
            <v-icon left small>mdi-{{ status.icon }}</v-icon>{{ status.title }}
            <template v-if="status.title === 'Todas'"></template>
          </v-tab>
        </v-tabs>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0 mt-25">
      <PermissionDenied v-if="!$helpers.hasSomePermission(['view_reconciliation'])" />
      <v-col class="px-0" v-else>
        <skeleton-list v-if="loading" />
        <template v-else>
          <v-col class="px-0 pb-16 mb-5" v-if="reconciliationList.length">
            <v-simple-table :height="heightTable" fixed-header :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]">
              <template v-slot:default>
                <VTableHeaders :headers="headers" @sort="setSort" />
                <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -5px; z-index: 2" />
                <tbody>
                  <tr
                    v-for="(item, i) in reconciliationList"
                    :key="i"
                    v-intersect="(i + 1) === reconciliationList?.length ? loadMore : ''"
                    @click="currentItem = item, dialogDetail = true"
                    style="cursor: pointer"
                  >
                    <td class="body-2">{{ item.id }}</td>
                    <td class="body-2 text-end">{{ item.date | date }}</td>
                    <td>
                      <v-tooltip color="white-0" right transition="scale-transition" :max-width="280"
                      :open-on-hover="item.status_message ? true : false">
                      <template v-slot:activator="{ on }">
                        <v-chip class="scroll" :color="stepperList.find(({ status }) => status.includes(item.status)).color"
                          v-on="item.status_message ? on : false" small label>
                          {{ stepperList.find(({ status }) => status.includes(item.status)).title }}
                          <v-icon right>mdi-{{ stepperList.find(({ status }) => status.includes(item.status)).icon
                            }}</v-icon>
                        </v-chip>
                      </template>
                      <span class="d-block px-3 py-2">{{ item.status_message }}</span>
                    </v-tooltip>
                    </td>
                    <td class="body-2">
                      <v-tooltip color="white-0" right transition="scale-transition" :max-width="280">
                        <template v-slot:activator="{ on }">
                          <span class="d-inline-block text-truncate" v-on="on" style="max-width: 99%;">{{ item.filename}}</span>
                        </template>
                        <span class="d-block pa-3 grey-700--text">{{ item.filename}}</span>
                      </v-tooltip>
                    </td>
                    <td class="body-2 text-end">{{ item.created | dateTimeSecond }}</td>
                    <td class="body-2 text-end">{{ item.file_count | number }}</td>
                    <td class="body-2 text-end">{{ (item.file_count - item.ok_count) | number }}</td>
                    <td class="text-right">
                      <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10"
                        transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mr-n4 scroll" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="grey-700" size="24">mdi-dots-vertical</v-icon></v-btn>
                        </template>
                        <v-list class="pa-0">
                          <v-list-item class="px-3" @click="download(item.id, item.filename)" :ripple="false">
                            <v-list-item-title class="body-1 grey-500--text d-flex align-center"><v-icon color="grey-700" size="20" left>mdi-file-document</v-icon>Exportar registros</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-empty-state v-else-if="(!reconciliationList?.length && !$route.query.ref) || (!reconciliationList?.length && $route.query.ref || !reconciliationList?.length)" title="No existen cuadraturas para mostrar" :isFree="!(hiddenBtnEmpty && isCreated)" :hiddenDescription="hiddenBtnEmpty && isCreated" type="documents" id="cuadraturas" :height="320" customClass="mt-n8">
            <template v-slot:actions>
              <template v-if="hiddenBtnEmpty && isCreated">
                <!-- <v-btn @click="activeFilter = true" color="blue-500" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn> -->
                <v-btn class="ml-2" @click="$router.push({query: {ref: 'initial'}}), isCreated = false" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
        </template>
      </v-col>
    </v-col>
    <!-- dialog create -->
    <v-dialog v-model="dialogCreate" :width="widthModalCreateErrors" persistent no-click-animation>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Cargar cuadratura</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreate = false, errors = [], clearInput()" icon small retain-focus-on-click><v-icon
                  color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-5 py-4">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-create-fl.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-0 pl-10">
              <v-list class="v-list-form pa-0 transparent">
                <v-list-item class="py-0">
                  <v-list-item-title class="d-flex align-center">Archivo
                    <v-tooltip color="grey-background" right max-width="250px" transition="scale-transition">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" right small color="grey-700">mdi-information</v-icon>
                      </template>
                      <span class="d-block px-3 py-2">Debes cargar un archivo comprimido (.txt) con las tramas de
                        los
                        documentos que deseas emitir.</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">
                    <v-file-input v-model="file" :error="errorFile" ref="file" @change="setUploadFile()" type="file"
                      accept=".zip, .txt" prepend="mdi-paper-clip" hide-details background-color="transparent"
                      placeholder="No se eligió ningún archivo" solo flat chips dense />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="mt-1">
                  <v-list-item-title class="d-flex align-center">Fecha de cuadratura</v-list-item-title>
                  <v-list-item-subtitle class="ml-n8 pr-2">
                    <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="$v.newReconcialiation.date.$model" :error="$v.newReconcialiation.date.$error" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                      </template>
                      <v-date-picker v-model="$v.newReconcialiation.date.$model" @input="dateMenu=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="270" color="blue-500">
                        <v-row class="mb-2 px-6" align="center" justify="end">
                          <v-btn class="body-1" :ripple="false" text color="blue-500" @click="() => { newReconcialiation.date=null; dateMenu=false }">Limpiar</v-btn>
                          <v-spacer />
                          <v-btn @click="() => { $v.newReconcialiation.date.$model=today; dateMenu=false }" color="blue-500">Hoy</v-btn>
                        </v-row>
                      </v-date-picker>
                    </v-menu>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn v-if="!errors.length" @click="dialogCreate = false, clearInput()" outlined
            color="grey-700">Cancelar</v-btn>
          <v-btn v-if="errors.length" @click="errors = [], clearInput()" outlined color="grey-700">Volver a
            subir</v-btn>
          <v-btn @click="!errors.length ? createReconciliation() : handleDialog()"
            :loading="!errors.length ? $store.state.reconciliations.createLoader : false"
            :disabled="(!!errorFile || !((file || {}).name || '').length && !!newReconcialiation.date)" color="blue-500">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create -->

    <!-- dialog detail -->
    <v-dialog v-model="dialogDetail" :width="630" persistent no-click-animation>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Detalle del estado</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDetail = false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-2 px-0">
          <v-card class="blue-500 pt-6 pb-7 mb-5 mx-5">
            <span class="px-4 subtitle-1 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 98%;">
              {{currentItem.filename}}
            </span>
            <span class="px-4 mt-n1 subtitle-2 white--text d-block">{{currentItem.id}}</span>
            <v-row class="ml-2 mt-6" align="end" no-gutters>
              <v-col class="py-0 px-2">
                <span class="d-block body-1 white--text">Fecha de cuadratura</span>
                <span class="subtitle-2 white--text">
                  <span class="font-weight-bold" v-if="currentItem.date">{{currentItem.date | date}}</span>
                  <span class="body-2 dark font-italic" v-else>Sin especificar</span>
                </span>
              </v-col>
              <v-col class="py-0 px-2">
                <span class="d-block body-1 white--text">Fecha de creación</span>
                <span class="subtitle-2 white--text">
                  <span class="font-weight-bold" v-if="currentItem.created">{{currentItem.created | dateTimeSecond}}</span>
                  <span class="body-2 dark font-italic" v-else>Sin especificar</span>
                </span>
              </v-col>
              <v-col class="py-0 px-2">
                <span class="d-block body-1 white--text">Fecha de actualización</span>
                <span class="subtitle-2 white--text">
                  <span class="font-weight-bold" v-if="currentItem.updated">{{currentItem.updated | dateTimeSecond}}</span>
                  <span class="body-2 dark font-italic" v-else>Sin especificar</span>
                </span>
              </v-col>
            </v-row>
            <v-row class="ml-2 mt-6" align="end" no-gutters>
              <v-col cols="4" class="py-0 px-2">
                <span class="d-block body-1 white--text">Estado</span>
                <v-chip class="scroll" :color="stepperList.find(({ status }) => status.includes(currentItem.status))?.color" small label>
                  {{ stepperList.find(({ status }) => status.includes(currentItem.status))?.title }}
                  <v-icon right>mdi-{{ stepperList.find(({ status }) => status.includes(currentItem.status))?.icon}}</v-icon>
                </v-chip>
              </v-col>
              <v-col class="py-0 px-2">
                <span class="d-block body-1 white--text">Detalle del estado</span>
                <span class="subtitle-2 white--text">
                  <span class="font-weight-bold" v-if="currentItem.status_info">{{currentItem.status_info}}</span>
                  <span class="body-2 dark font-italic" v-else>Sin especificar</span>
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-divider />
          <v-col class="pl-5 pr-6">
            <span class="d-block mt-3 grey-700--text subtitle-2 font-weight-semibold">Total de documentos</span>
            <v-row>
              <v-col class="px-3 mt-2">
                <span class="mr-1 text-h4 grey-700--text">{{ currentItem.file_count | number }}</span> <span class="body-1 grey-700--text">documentos cargados</span>
                <div class="mt-2 d-flex align-center">
                  <v-avatar class="mr-1" color="blue-500" size="12" />
                  <span class="mx-2 text-h6 grey-700--text">{{ currentItem.ok_count | number }}</span> <span class="body-1 grey-700--text">cuadrados OK</span>
                </div>
                <div class="mt-n1 d-flex align-center">
                  <v-avatar class="mr-1" color="error" size="12" />
                  <span class="mx-2 text-h6 grey-700--text">{{ (currentItem.file_count - currentItem.ok_count) | number }}</span> <span class="body-1 grey-700--text">incidencias</span>
                </div>
                <div class="mt-n1 d-flex align-center">
                  <v-avatar class="mr-1" color="error" size="12" />
                  <span class="mx-2 text-h6 grey-700--text">$ {{ currentItem.failed_amount | number }}</span> <span class="body-1 grey-700--text">diferencias absolutas de monto</span>
                </div>
                <div class="mt-n1 d-flex align-center">
                  <v-avatar class="mr-1" color="error" size="12" />
                  <span class="mx-2 text-h6 grey-700--text">{{ currentItem.axt_duplicated_count | number }}</span> <span class="body-1 grey-700--text">documentos duplicados en Axteroid</span>
                </div>
              </v-col>
              <v-divider class="my-4 grey-200" vertical style="max-height: 25px !important" />
              <v-col cols="1"></v-col>
              <v-col cols="5" class="px-3 mt-2 text-right">
                <span class="mr-1 text-h4 grey-700--text">{{ currentItem.db_count | number }}</span> <span class="body-1 grey-700--text">documentos en Axteroid</span>
                <div class="mt-11">
                  <span class="body-1 error--text font-weight-semibold">{{ (((currentItem.file_count - currentItem.ok_count) * 100) / currentItem.file_count) | number }}%</span> <span class="body-1 grey-700--text">de total</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn @click="download(currentItem.id, currentItem.filename)" outlined color="grey-700" :loading="loadingExport">Exportar registros</v-btn>
          <v-btn @click="dialogDetail = false" color="blue-500">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog detail -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import MiniHeader from '@/components/commons/MiniHeader'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import { saveAs } from 'file-saver'

export default {
  components: {
    VQueryBuilder,
    VEmptyState,
    SkeletonList,
    VMainPagination,
    PermissionDenied,
    MiniHeader,
    VTableHeaders
  },
  mixins: [
    ListViewMixin, GenericViewMixin
  ],
  data: () => ({
    isCreated: true,
    isUpdate: false,
    resetTab: 0,
    loadingExport: false,
    loadingExportTable: [false],
    currentItem: {},
    dialogDetail: false,
    dateMenu: false,
    moreLoading: false,
    isLoadMore: true,
    loading: false,
    reconciliationList: [],
    errorFile: false,
    scroll: 0,
    errors: [],
    file: null,
    newReconcialiation: {
      file: null,
      date: null
    },
    importFormats: ['csv', 'xlsx', 'json'],
    filter: 0,
    dialogCreate: false,
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Fecha de cuadratura', value: 'date', align: 'end', sortable: false },
      { text: 'Estado', value: 'status', show: true, disabled: false },
      { text: 'Nombre del archivo', value: 'filename', sortable: false },
      { text: 'Fecha de creación', value: 'created', align: 'end', sortable: false },
      { text: 'Cant.', value: 'file_count', align: 'end', sortable: false },
      { text: 'Diferencias', value: 'ok_count', align: 'end' },
      { text: '', value: 'actions', align: 'end' }
    ],
    stepperList: [
      {
        id: 0,
        status: [],
        title: 'Todas',
        icon: 'ballot'
        // width: 'auto'
      },
      {
        id: 1,
        status: ['CRE'],
        title: 'Cargado',
        icon: 'upload',
        color: 'yellow-100'
        // width: 130
      },
      {
        id: 2,
        status: ['RUN'],
        title: 'En proceso',
        icon: 'cog',
        color: 'yellow-100'
        // width: 100
      },
      {
        id: 3,
        status: ['PRO'],
        title: 'Completado',
        icon: 'check',
        color: 'blue-100'
        // width: 110
      },
      {
        id: 4,
        status: ['FAI'],
        title: 'Fallido',
        icon: 'close',
        color: 'red-100'
      }
    ],
    breadcrumbs: {
      main: 'Emisión',
      children: [
        {
          text: 'Cuadratura'
        }
      ]
    }
  }),
  computed: {
    ...mapState({
      count: state => state.reconciliations.reconciliationsCount,
      documentsList: state => state.reconciliations.reconciliationsList
    }),
    widthModalCreateErrors () {
      return this.errors.length ? '715' : '620'
    },
    heightTable () {
      if (this.$vuetify.breakpoint.height < 900) return 'calc(100vh - 300px)'
      // else if (this.documentsList.length < 20) return 'auto'
      return 'calc(100vh - 460px)'
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      return false
    }
  },
  watch: {
    filter (val) {
      if (val > 0) {
        let query = Object.values(this.stepperList.find((item) => item.id === val).status).toString()
        this.isCreated = true
        this.$router.replace({ params: this.$route.params, name: this.$route.name, query: { status: query } }).catch(() => { })
      } else this.$router.replace({ params: this.$route.params, name: this.$route.name, query: null }).catch(() => { })

      this.isLoadMore = true
    },
    $route () {
      if (!this.$route.query?.status) {
        this.filter = 0
        this.resetTab += 1
      }
    }
  },
  created () {
    if (this.$route.query.status) {
      const index = this.stepperList.findIndex((item) => this.$route.query.status.split(',').every((s) => item.status?.includes(s)))
      if (index > 0) this.filter = index
    }
    if ((Object.keys(this.$route?.query)?.length) && this.$helpers.hasSomePermission(['view_reconciliation'])) this.getList()
  },
  methods: {
    loadMore (entries, observer, isIntersecting) {
      if (isIntersecting && this.isLoadMore) {
        this.moreLoading = true
        this.$store.dispatch('reconciliations/LIST', {
          resource: 'reconciliations',
          query: {
            ...this.$route.query,
            last_id: this.documentsList[this.documentsList.length - 1]?.id
          }
        })
        .then((response) => {
          this.isLoadMore = !!response.data.length
          if (this.isLoadMore) {
            this.reconciliationList = [...this.reconciliationList, ...response.data]
            this.pagination = this.reconciliationList.length
          }
        })
        .finally(() => {
          this.moreLoading = false
        })
      }
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    download (id = '', name = '', isTable = false) {
      this.loadingExport = true
      this.$store.dispatch('reconciliations/RETRIEVE', {
        resource: 'reconciliations',
        id: id,
        loader: true,
        query: {
          format: 'report'
        },
        responseType: 'arraybuffer'
      })
      .then((response) => {
        const content = Buffer.from(response.data, 'base64')
        saveAs(new Blob([content], { type: 'application/xlsx' }), `${name}.xlsx`)
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en la descarga')
      })
      .finally(() => {
        this.loadingExport = false
      })
  },
    getList () {
      if (Object.keys(this.$route?.query)?.length || this.isUpdate) {
        this.loading = true
        this.$store.dispatch('reconciliations/LIST', {
          resource: 'reconciliations',
          query: this.$route.query
        })
        .then(() => {
          this.reconciliationList = this.documentsList
        })
        .finally(() => {
          this.loading = false
        })
      } else this.reconciliationList = []
    },
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'DocumentsBatchRetrieve', params: { id: id, ...this.currentParams } })
    },
    setUploadFile () {
      this.errorFile = (this.file?.name?.search('txt') === -1)
    },
    createReconciliation (error = false) {
      this.newReconcialiation.file = this.file
      this.$v.newReconcialiation.$touch()
      if (this.$v.newReconcialiation.$invalid) {
        return false
      }
      const payload = new FormData()
      payload.append('file', this.newReconcialiation.file, this.newReconcialiation.file.name)
      payload.append('date', this.newReconcialiation.date)
      this.$store.dispatch('reconciliations/CREATE', {
        resource: 'reconciliations',
        payload: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        // this.$router.push({ name: 'DocumentsBatchRetrieve', params: { id: response.data.id, ...this.currentParams } })
        this.dialogCreate = false
        this.getList()
        this.$dialog.message.info('La cuadratura se ha creado con éxito')
        this.clearInput()
      })
      .catch((error) => {
        this.errors = error.response.data
        this.$dialog.message.error(error.response.data.message)
      })
    },
    clearInput () {
      this.file = null
      this.newReconcialiation.file = null
      this.newReconcialiation.date = null
      this.$v.newReconcialiation.$reset()
    },
    handleDialog () {
      this.createReconciliation(true)
      this.dialogCreate = false
      this.errors = []
      this.clearInput()
    }
  },
  validations: {
    newReconcialiation: {
      file: {
        required
      },
      date: {
        required
      }
    }
  }
}
</script>